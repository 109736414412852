$(document).on('turbolinks:load', function () {
  jQuery(function () {

    $('.top-cnt').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
      if (isInView) {
        //要素が見えたときに実行する処理
        $('.slideTopLogo').addClass("slideTopLogoIn");
      } else {
        //要素が見えなくなったときに実行する処理
        $('.slideTopLogo').removeClass("slideTopLogoIn");
      }
    });

    $('.top-cnt').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
      if (isInView) {
        //要素が見えたときに実行する処理
        $('.slideTopTime').addClass("slideTopTimeIn");
      } else {
        //要素が見えなくなったときに実行する処理
        $('.slideTopTime').removeClass("slideTopTimeIn");
      }
    });

    $('.new-cnt').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
      if (isInView) {
        //要素が見えたときに実行する処理
        $('.slideNewsContsL').addClass("slideNewsContsLIn");
      } else {
        //要素が見えなくなったときに実行する処理
        $('.slideNewsContsL').removeClass("slideNewsContsLIn");
      }
    });

    $('.new-cnt').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
      if (isInView) {
        //要素が見えたときに実行する処理
        $('.slideNewsContsR').addClass("slideNewsContsRIn");
      } else {
        //要素が見えなくなったときに実行する処理
        $('.slideNewsContsR').removeClass("slideNewsContsRIn");
      }
    });
    $('.showid-cnt-under').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
      if (isInView) {
        //要素が見えたときに実行する処理
        $('.slideNewsUnderL').addClass("slideNewsUnderLIn");
      } else {
        //要素が見えなくなったときに実行する処理
        $('.slideNewsUnderL').removeClass("slideNewsUnderLIn");
      }
    });

    $('.showid-cnt-under').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
      if (isInView) {
        //要素が見えたときに実行する処理
        $('.slideNewsUnderR').addClass("slideNewsUnderRIn");
      } else {
        //要素が見えなくなったときに実行する処理
        $('.slideNewsUnderR').removeClass("slideNewsUnderRIn");
      }
    });


  });
});
//inview

//slide
var slideConts = document.querySelectorAll('.slideConts'); // スライドで表示させる要素の取得
var slideContsRect = []; // 要素の位置を入れるための配列
var slideContsTop = []; // 要素の位置を入れるための配列
var windowY = window.pageYOffset; // ウィンドウのスクロール位置を取得
var windowH = window.innerHeight; // ウィンドウの高さを取得
var remainder = 100; // ちょっとはみ出させる部分
// 要素の位置を取得
for (var i = 0; i < slideConts.length; i++) {
  slideContsRect.push(slideConts[i].getBoundingClientRect());
}
for (var i = 0; i < slideContsRect.length; i++) {
  slideContsTop.push(slideContsRect[i].top + windowY);
}
// ウィンドウがリサイズされたら、ウィンドウの高さを再取得
window.addEventListener('resize', function () {
  windowH = window.innerHeight;
});
// スクロールされたら
window.addEventListener('scroll', function () {
  // スクロール位置を取得
  windowY = window.pageYOffset;

  for (var i = 0; i < slideConts.length; i++) {
    // 要素が画面の下端にかかったら
    if (windowY > slideContsTop[i] - windowH + remainder) {
      // .showを付与
      slideConts[i].classList.add('show');
    } else {
      // 逆に.showを削除
      slideConts[i].classList.remove('show');
    }
  }
});
//slide

//table_sticky
$(document).on('turbolinks:load', function () {
  $(function () {
    //load stickyTable with overflowy option
    $('#myTable').stickyTable({
      overflowy: true
    });

    $('#destroyBtn').click(function () {
      //removes sticky table classes and elements
      try {
        $('#myTable').stickyTable('destroy');
      } catch (e) {
        console.log(e.message);
      }
    });

    $('#initBtn').click(function () {
      try {
        $('#myTable').stickyTable('destroy');
      } catch (e) {
        console.log(e.message);
      }

      /*var options = {};
      options.copyTableClass = $('#copyTableClass').is(':checked');
      options.overflowy = $('#overflowy').is(':checked');
      $('#myTable').stickyTable(options);*/
    });
  });
});
//table_sticky

//bg_size_cover
$(document).on('turbolinks:load', function () {
  $(function () {
    var makeBackgroundCover = function (div) {
      $(div + " img").css("height", "100%");
      if ($(div + " img").width() < $(div).width()) {
        $(div + " img").css({
          "height": "auto",
          "width": "100%"
        });
      }
    }
    makeBackgroundCover("#cover");
  });
});
//bg_size_cover

//page_top
$(document).on('turbolinks:load', function () {
  jQuery(function () {
    var appear = false;
    var pagetop = $('#page_top');
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) { //100pxスクロールしたら
        if (appear == false) {
          appear = true;
          pagetop.stop().animate({
            //'bottom': '-100px' //下から50pxの位置に
            'right': '0'
          }, 300); //0.3秒かけて現れる
        }
      } else {
        if (appear) {
          appear = false;
          pagetop.stop().animate({
            //'bottom': '-100px' //下から50pxの位置に
            'right': '-100px'
          }, 300); //0.3秒かけて隠れる
        }
      }
    });
    pagetop.click(function () {
      $('body, html').animate({
        scrollTop: 0
      }, 500); //0.5秒かけてトップへ戻る
      return false;
    });
  });
});
//page_top