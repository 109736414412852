$( document ).on('turbolinks:load', function() {
  $('.table-sortable').sortable({
    axis: 'y',
    items: '.item',
    
    update: function(e, ui){      
      var item = ui.item;
      var item_data = item.data();
      var params = {_method: 'put'};
      params[item_data.modelName] = { row_order_position: item.index() } //フォーム以外の並び替え（same_withでズレない）
      //params[item_data.modelName] = { row_order_position: Math.ceil(e.newIndex / 2) - 1, method_id: item.dataset.methodId }
      //params[item_data.modelName] = { row_order_position: item.index(e.newIndex / 2) - 1 } //フォームの並び替え（nested_form_for内の並び替えがsame_withでも効かないため）
      //params[item_data.modelName] = { row_order_position: item.index(e.newIndex / 2) -1 }
      
      $.ajax({
        type: 'POST',
        url: item_data.updateUrl,
        dataType: 'json',
        data: params
      });
    },
    
    // ドラッグ幅をテーブルに合わせる
    start: function(e, ui) {
      var cells, tableWidth, widthForEachCell;
      tableWidth = $(this).width();
      cells = ui.item.children('td');
      widthForEachCell = tableWidth / cells.length + 'px';
      return cells.css('width', widthForEachCell);
    },

    // エフェクトを付与
    stop: function(e, ui) {
      return ui.item.children('td').effect('highlight');
    }
  });
});