  $(window).scroll(function () {
    let scroll_len = $(window).scrollTop();
    let scrollHeight = $(document).height();
    let scrollPosition = $(window).height() + $(window).scrollTop();
    if ((scrollHeight - scrollPosition) / scrollHeight <= 0.05) {
      $('.bottom-menu').animate({
        height: 'hide'
      }, 'slow');
    } else if (scroll_len > 100) {
      $('.bottom-menu').animate({
        height: 'show'
      }, 'slow');
    } else {
      $('.bottom-menu').animate({
        height: 'hide'
      }, 'slow');
    }
  });