// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
import "channels"


import "jquery"

//import "crop_image"
//import "cropper.min"
import "cropper"
//import Cropper from "cropperjs"
import "jquery-cropper.min"

import "bootstrap"
//import "jquery-ui"



//import "jquery-cropper.min"

//import "crop_image"

import "jquery_nested_form"
import "jquery-ui/ui/widgets/sortable"
import "jquery-ui/ui/effects/effect-highlight"
import "table_sort"



import "bottom_scroll"
import "jquery.inview.min"
import "base2"
import "jquery.sticky-sidebar"



import "chart.js"



//fontawesomeはbootstrapの上に配置
import "@fortawesome/fontawesome-free/js/all"
//import "bootstrap"

import "../stylesheets/application"



Rails.start()
Turbolinks.start()
ActiveStorage.start()




//import "pdfmake/pdfmake"
//import "pdfmake/vfs_fonts"
//import "pdfmake/browser"


//import "base"
